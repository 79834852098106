<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-card>
        <v-toolbar dark color="purple light" class="mt-16">
          <v-toolbar-title>
            Please login
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="email"
            autofocus
            color="accent"
            label="Email"
            required
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            color="accent"
            label="Password"
            required
          >
          </v-text-field>
          <v-alert v-model="alert" type="error"
            >로그인 정보를 확인하세요.</v-alert
          >
        </v-card-text>
        <v-card-actions class="px-3 pb-3">
          <v-flex text-xs-right>
            <v-btn dark @click="submitLogin()" color="purple light-1"
              >Submit</v-btn
            >
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      alert: false,
    };
  },
  methods: {
    submitLogin() {
      axios
        .post('https://shrouded-headland-21000.herokuapp.com/api/users/login', {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          this.$store.dispatch('login', data);
          this.$router.push({ name: 'Home' });
          window.location.reload();
        })
        .catch((err) => {
          this.alert = true;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
